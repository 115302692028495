import _ from 'lodash';

import { ConditionGroupMetadata } from '../features/selectedNode/selectedNodeSlice';
import {
  Action,
  ActionOperator,
  IfThenRuleConditionWithId,
  IfThenWithConditionIds,
} from '../types';
import { parseConditionJoiner } from './parseConditionJoiner';

export function conditionsHaveAnyBlankFields(ifThens: IfThenWithConditionIds[] = []): boolean {
  for (const ifThen of ifThens) {
    if (actionHasAnyBlankFields(ifThen.action)) {
      return true;
    }
    const joiner = parseConditionJoiner(ifThen);
    const conditions = ifThen.rule.conditions[joiner] ?? [];
    for (const condition of conditions) {
      const valueIsNullish = _.isObject(condition.value)
        ? hasNullishValues(condition.value)
        : falsifiableValueIsNullish(condition.value);

      if (valueIsNullish || !condition.operator || !condition.fact) {
        return true;
      }
    }
  }

  return false;
}

export function conditionsHaveAnyBlankFields2(
  conditions: IfThenRuleConditionWithId[],
  actions: Action[],
): boolean {
  if (_.some(actions, (action) => actionHasAnyBlankFields(action))) {
    return true;
  }

  if (
    _.some(conditions, (condition) => {
      const valueIsNullish = _.isObject(condition.value)
        ? hasNullishValues(condition.value)
        : falsifiableValueIsNullish(condition.value);

      return valueIsNullish || !condition.operator || !condition.fact;
    })
  ) {
    return true;
  }

  return false;
}

export function ifThensAreEmpty(ifThens: IfThenWithConditionIds[] = []): boolean {
  for (const ifThen of ifThens) {
    const joiner = parseConditionJoiner(ifThen);
    if (!ifThen.rule.conditions?.[joiner]?.length && !ifThen.nextNodeId) {
      return true;
    }
  }

  return false;
}

export function conditionGroupsAreEmpty(
  conditionGroupToConditionIds: Record<string, string[]>,
  conditionGroupMetadata: Record<string, ConditionGroupMetadata>,
): boolean {
  return _.some(Object.keys(conditionGroupToConditionIds), (conditionGroupId) => {
    if (
      conditionGroupToConditionIds[conditionGroupId].length &&
      !conditionGroupMetadata[conditionGroupId].nextNodeId
    )
      return (
        conditionGroupToConditionIds[conditionGroupId].length &&
        !conditionGroupMetadata[conditionGroupId].nextNodeId
      );
  });
}

export function actionHasAnyBlankFields(action?: Action) {
  if (action) {
    if (action.type === 'alsoRoutesLegacy' || action.type === 'onHold') {
      return false;
    }

    if (action.type === 'filter' && !action.cause) {
      return true;
    }
    if (!action.operator || (!action.path && action.path !== '')) {
      return true;
    }

    // This should probably exist on a configuration for the margin operator?
    if (action.operator === ActionOperator.margin && (!action.value || !action.value.percentage)) {
      return true;
    }

    if (action.value && _.isObject(action.value)) {
      for (const key of Object.keys(action.value)) {
        if (key === '') {
          return true;
        }
      }
    }
  }
  return false;
}

export function falsifiableValueIsNullish(value: any) {
  return value === null || value === undefined || value === '';
}

export function hasNullishValues(value: Record<any, any> | any[]) {
  if (_.isArray(value)) {
    if (!value.length) {
      return true;
    }
    for (const v of value) {
      if (v === '' || v === null || v === undefined) {
        return true;
      }
    }
    return false;
  }

  const nullishProperties = _.flatMap(value, (v) => _.values(v)).filter((v) =>
    falsifiableValueIsNullish(v),
  );

  return nullishProperties.length > 0;
}

export function getRequiredInputStatus(value: any) {
  return value ? 'success' : 'error';
}

export function getRequiredInputStatusOnMaybeFalseValue(value: any) {
  return falsifiableValueIsNullish(value) ? 'error' : 'success';
}
