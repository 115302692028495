/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import React from 'react';

import { Select } from '@cimpress/react-components';

// Access react-select inner component styles
import './valueDropdownSelect.scss';

const StyledValueDropdownSelect = styled(Select)`
  max-width: 210px;
  min-width: 100px;
`;

export default function ValueDropdownSelect(props) {
  return <StyledValueDropdownSelect {...props} classNamePrefix={'value-dropdown'} />;
}
