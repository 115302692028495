/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import _ from 'lodash';
import React, { ReactNode } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AccountListWithErrorBoundary from '@cimpress-technology/account-selector';
import IconNavigationNext from '@cimpress-technology/react-streamline-icons/lib/IconNavigationNext';

import { selectTesterTab } from '../../features/tabs/tabSlice';
import { useAppDispatch } from '../../store/hooks';
import AppLinkButton from './AppLinkButton';

type Account = {
  accountId: string | undefined;
};

type AppLinkType = 'default' | 'dropdown';

type AppLink = {
  id: string;
  type?: AppLinkType;
  content?: ReactNode;
  title?: string;
  isActive?: boolean;
};

const Navigation = ({ text }: { text: string }) => {
  return (
    <div css={navigationStyles}>
      {text} <IconNavigationNext size="lg" />
    </div>
  );
};

export function GetAppLinks({
  showAccountSelector,
  auth,
  account,
  setAccount,
}: {
  showAccountSelector: boolean;
  auth: any;
  account: Account;
  setAccount: (account: Account) => void;
}) {
  const navigate = useNavigate();
  const { accountId } = useParams();

  const dispatch = useAppDispatch();
  const dispatchTesterTab = () => dispatch(selectTesterTab());

  const appLinks: AppLink[] = [
    {
      id: 'routing-tools',
      title: 'Routing Tools',
      type: 'dropdown',
      content: [
        <AppLinkButton
          key="equivalency-button"
          variant="anchor"
          onClick={() => window.open(process.env.REACT_APP_EQUIVALENCY_URL, '_blank')}
        >
          <Navigation text="Equivalency" />
        </AppLinkButton>,
        <AppLinkButton
          key="capacity-button"
          variant="anchor"
          onClick={() => window.open(process.env.REACT_APP_MARKETPLACE_CAPACITY_URL, '_blank')}
        >
          <Navigation text="Capacity" />
        </AppLinkButton>,
        <AppLinkButton
          key="on-hold-button"
          variant="anchor"
          onClick={() => window.open(process.env.REACT_APP_NOMS_ON_HOLD_ORDERS_URL, '_blank')}
        >
          <Navigation text="On hold orders" />
        </AppLinkButton>,
        <AppLinkButton
          key="view-decisions-button"
          variant="anchor"
          onClick={() => window.open(process.env.REACT_APP_NOMS_VIEW_DECISIONS_URL, '_blank')}
        >
          <Navigation text="Routing decisions" />
        </AppLinkButton>,
        <AppLinkButton
          key="route-tester-button"
          variant="anchor"
          onClick={() => {
            dispatchTesterTab();
            navigate(`/accounts/${accountId}/configurations/evaluate`);
          }}
        >
          <div css={navigationStyles}>Route Tester</div>
        </AppLinkButton>,
      ],
    },
  ];

  // This places it all the way at the right no matter what.
  // This component will throw warnings in development
  // about account.id being undefined. This can't be fixed here;
  // it has to be updated in the AccountList component itself.
  if (showAccountSelector && !_.find(appLinks, (a) => a.id === 'buyer')) {
    appLinks.push(
      ...[
        {
          id: 'divider',
          type: 'default' as AppLinkType,
          content: <div css={verticalDivider}></div>,
        },
        {
          id: 'buyer',
          type: 'default' as AppLinkType,
          content: (
            <a>
              <AccountListWithErrorBoundary auth={auth} account={account} setAccount={setAccount} />
            </a>
          ),
        } as AppLink,
      ],
    );
  }

  return appLinks;
}

const verticalDivider = css`
  border-right: 1px solid #c4cdd6;
  height: 30px;
  width: 1px;
  margin: 10px 20px;
`;

const navigationStyles = css`
  display: flex;
  align-items: center;
  gap: 10px;
`;
