import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { Button, Spinner } from '@cimpress/react-components';

import { runRoutingEvaluation } from '../../features/evaluationResults/evaluationResultsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { assembleRoutingEvalArgs } from '../../utils/assembleRoutingEvalArgs';
import PopulateFromItemOrOrder from './PopulateFromItemOrOrder';
import ShareTesterUrlModal from './ShareTesterUrlModal';
import TesterFormFields from './TesterFormFields';
import WhatToExpectModal from './WhatToExpectModal';
import styles from './formContainer.module.scss';

export default function FormContainer() {
  const dispatch = useAppDispatch();
  const { accountId } = useParams();

  const testInputIsValid = useAppSelector((state) => state.testInput.testInputIsValid);
  const inputState = useAppSelector((state) => state.testInput);

  const fulfillmentOptionsLink = useAppSelector(
    (state) => state.resourceSearch.fulfillmentOptionsLink,
  );
  const routingRequestLink = useAppSelector((state) => state.resourceSearch.routingRequestLink);
  const workingConfiguration = useAppSelector(
    (state) => state.selectedConfiguration.workingConfiguration,
  );
  const baseConfiguration = useAppSelector(
    (state) => state.selectedConfiguration.routingConfiguration,
  );
  const isLoadingEvaluation = useAppSelector((state) => state.evaluationResults.loading);

  const productConfigurationUrl = useAppSelector(
    (state) => state.productConfiguration.productConfigurationUrl,
  );

  const [showWhatToExpectModal, setShowWhatToExpectModal] = useState(false);
  const [showTesterUrlModal, setShowTesterUrlModal] = useState(false);

  const handleEvaluationClick = () => {
    const args = assembleRoutingEvalArgs({
      inputState,
      accountId: accountId!,
      selectedItem: inputState.selectedItem!,
      fulfillmentOptionsLink: fulfillmentOptionsLink,
      routingRequestLink: routingRequestLink!,
      workingConfiguration: workingConfiguration!,
      baseConfiguration: baseConfiguration!,
      orderItems: inputState.orderItems,
      productConfigurationUrl,
    });
    dispatch(runRoutingEvaluation(args));
  };

  const [evaluationDisabled, setEvaluationDisabled] = useState(
    !testInputIsValid ||
      !accountId ||
      !inputState?.selectedItem ||
      !routingRequestLink ||
      !workingConfiguration ||
      !baseConfiguration,
  );

  useEffect(() => {
    setEvaluationDisabled(
      !testInputIsValid ||
        !accountId ||
        !inputState?.selectedItem ||
        !routingRequestLink ||
        !workingConfiguration ||
        isLoadingEvaluation ||
        !baseConfiguration,
    );
  }, [
    testInputIsValid,
    accountId,
    JSON.stringify(inputState),
    routingRequestLink,
    JSON.stringify(workingConfiguration),
    JSON.stringify(baseConfiguration),
    isLoadingEvaluation,
  ]);

  return (
    <div className={styles.container}>
      <WhatToExpectModal
        show={showWhatToExpectModal}
        close={() => setShowWhatToExpectModal(false)}
      />
      <ShareTesterUrlModal show={showTesterUrlModal} close={() => setShowTesterUrlModal(false)} />
      <div className={styles.subContainer}>
        <div className={styles.headerFlexContainer}>
          <h2 className={styles.header}>Route Tester</h2>
          <Button onClick={() => setShowWhatToExpectModal(true)}>What to expect</Button>
        </div>
        <p>
          Search an existing item or order, and optionally edit the order information, to test which
          supplier an item would be routed to.
        </p>

        <PopulateFromItemOrOrder key={uuid()} />
        {inputState?.selectedItem && <TesterFormFields key="tester-form-fields" />}

        <Button
          className={styles.testButton}
          variant="secondary"
          onClick={() => setShowTesterUrlModal(true)}
          disabled={
            !accountId ||
            !productConfigurationUrl ||
            !routingRequestLink ||
            !workingConfiguration ||
            !inputState.selectedItem
          }
        >
          Get Shareable Tester Url
        </Button>

        <div className={styles.buttonFlexDiv}>
          <Button
            className={styles.testButton}
            variant="primary"
            onClick={handleEvaluationClick}
            disabled={evaluationDisabled}
          >
            Test Routing Configuration
          </Button>
          {isLoadingEvaluation && <Spinner className={styles.spinnerContainer} size="medium" />}
        </div>
      </div>
    </div>
  );
}
