/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { Select } from '@cimpress/react-components';

import { DisplayValue } from '../../types';
import TrashIconButton from '../styledComponents/TrashIconButton';

export default function IfThenCheckRow({
  conditionId,
  index,
  value,
  options,
  onChange,
  onDelete,
}: {
  conditionId: string;
  index: number;
  value: DisplayValue<string>;
  options: DisplayValue<string>[];
  onChange: (updated: DisplayValue<string>) => void;
  onDelete: (index: number) => void;
}) {
  return (
    <div css={ifThenCheckFlexRow} key={`${conditionId}-ifThenCheckRow-${index}`}>
      <Select
        label="Condition Group"
        options={options}
        value={value}
        onChange={onChange}
        css={selectStyles}
      />
      <TrashIconButton onDelete={onDelete} />
    </div>
  );
}

const ifThenCheckFlexRow = css`
  display: flex;
  height: auto;
  justify-content: space-between;
`;

const selectStyles = css`
  width: 394px;
`;
