/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Select, SelectProps } from '@cimpress/react-components';

// Access react-select inner component styles
import './operatorSelect.scss';

const StyledOperatorSelect = styled(Select)`
  min-width: 100px;
  max-width: 210px;
`;

const OperatorSelect: React.FC<SelectProps> = (props) => {
  return (
    <div css={operatorWrapperCss}>
      <StyledOperatorSelect {...props} classNamePrefix={'operator'} />
    </div>
  );
};

export default OperatorSelect;

const operatorWrapperCss = css`
  grid-area: operator;
`;
