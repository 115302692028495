import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import { WorkingRoutingConfigurationNode, WorkingRoutingConfigurationV3 } from '../types';

export function disconnectNode({
  rc,
  nodeId,
}: {
  rc: WorkingRoutingConfigurationV3;
  nodeId: string;
}) {
  const targetNode = _.find(rc.nodes, (node) => node.id === nodeId)!;
  const defaultNextNodeOfTarget = targetNode.defaultNextNodeId;

  // If we're removing the starting node,
  // then find or create a new starting node.
  if (rc.startingNodeId === targetNode.id) {
    const blankNode: WorkingRoutingConfigurationNode = {
      id: uuid(),
      name: 'New Blank Node',
    };

    const newStartingNode =
      _.find(rc.nodes, (node) => node.id === defaultNextNodeOfTarget) || blankNode;
    rc.startingNodeId = newStartingNode.id;

    if (rc.startingNodeId === blankNode.id) {
      rc.nodes.push(blankNode);
    }
    // There are no other references to the target node
    // that need to change, so we're done.
    return;
  }

  // Everywhere we would've targeted the disconnected node,
  // instead target the disconnected node's default next node
  // (which might be undefined).
  _.forEach(rc.nodes, (node) => {
    if (node.defaultNextNodeId === nodeId) {
      node.defaultNextNodeId = defaultNextNodeOfTarget;
    }

    _.forEach(node.ifThens || [], (ifThen) => {
      if (ifThen.nextNodeId === nodeId) {
        ifThen.nextNodeId = defaultNextNodeOfTarget;
      }
    });
  });
}

export function removeOutgoingNodeReferences(node: WorkingRoutingConfigurationNode) {
  node.defaultNextNodeId = undefined;
  _.forEach(node.ifThens || [], (ifThen) => (ifThen.nextNodeId = undefined));
}

export function removeReferencesToNodeId({
  rc,
  nodeId,
}: {
  rc: WorkingRoutingConfigurationV3;
  nodeId: string;
}) {
  _.forEach(rc.nodes, (node) => {
    if (node.defaultNextNodeId === nodeId) {
      node.defaultNextNodeId = undefined;
    }
    _.forEach(node.ifThens || [], (ifThen) => {
      if (ifThen.nextNodeId === nodeId) ifThen.nextNodeId = undefined;
    });
  });
}

export function deleteNode({ rc, nodeId }: { rc: WorkingRoutingConfigurationV3; nodeId: string }) {
  rc.nodes = _.filter(rc.nodes, (node) => node.id !== nodeId);
  removeReferencesToNodeId({ rc, nodeId });
}

export function possibleNextNodeIds(node: WorkingRoutingConfigurationNode) {
  const nextNodes: Set<string> = new Set();
  _.forEach(node.ifThens, (ifThen) => {
    if (ifThen.nextNodeId) {
      nextNodes.add(ifThen.nextNodeId);
    }
  });

  if (node.defaultNextNodeId) {
    nextNodes.add(node.defaultNextNodeId);
  }

  if (node.ifThensPassedNextNodeId) {
    nextNodes.add(node.ifThensPassedNextNodeId);
  }

  return Array.from(nextNodes);
}

export function getValidDownstreamNodes(
  nodes: WorkingRoutingConfigurationNode[],
  targetNodeId?: string,
) {
  if (!targetNodeId) {
    return [];
  }
  const [canReachTarget, cannotReachTarget] = _.partition(
    nodes,
    (node) => partitionByReachability([node], targetNodeId)[0].length > 0,
  );

  let target: WorkingRoutingConfigurationNode | undefined;
  const seenNodeIds = new Set<string>();

  while ((target = canReachTarget.pop())) {
    if (seenNodeIds.has(target.id)) {
      continue;
    }
    const [canReachNewTarget] = partitionByReachability(nodes, target.id);
    _.remove(
      cannotReachTarget,
      (node) => _.findIndex(canReachNewTarget, (n) => n.id === node.id) >= 0,
    );
    canReachTarget.push(...canReachNewTarget);
    seenNodeIds.add(target.id);
  }
  _.remove(cannotReachTarget, (node) => node.id === targetNodeId);

  return cannotReachTarget;
}

function partitionByReachability(nodes: WorkingRoutingConfigurationNode[], targetNodeId?: string) {
  return _.partition(nodes, (node) => {
    // The node cannot reach itself
    if (node.id === targetNodeId) {
      return false;
    }

    if (node.defaultNextNodeId === targetNodeId) {
      return true;
    }

    const anyIfThenCanReachTarget = _.find(node.ifThens || [], (ifThen) => {
      return ifThen.nextNodeId === targetNodeId;
    });

    return Boolean(anyIfThenCanReachTarget);
  });
}
