import React from 'react';

import { Tooltip } from '@cimpress/react-components';

const textForDefault = 'This is the next Node that will be evaluated if no Condition Groups pass.';
const textForConditionGroup = `
This is the next Node that will be evaluated if this Condition Group passes,
regardless of any Action that may be applied.
`;

const textForConditionGroupContinued = `
Due to Continue Condition Group Evaluation being selected, 
this is the next Node that will be evaluated if ANY Condition Group passes.
`;

const textNoneRemaining = `
There are no unused Nodes available to select. If you want to select a next Node, please
create a Node and ensure that it is not used higher up in the Routing Configuration.
`;

export default function NextNodeTooltipWrapper({
  disabled = false,
  level = 'default',
  children,
}: {
  disabled?: boolean;
  level: 'default' | 'conditionGroup' | 'conditionGroupContinued' | 'noneRemaining';
  children: React.ReactNode;
}) {
  let contents: string;

  switch (level) {
    case 'default':
      contents = textForDefault;
      break;
    case 'conditionGroup':
      contents = textForConditionGroup;
      break;
    case 'conditionGroupContinued':
      contents = textForConditionGroupContinued;
      break;
    default:
      contents = textNoneRemaining;
  }

  return disabled ? (
    <>{children}</>
  ) : (
    <Tooltip style={toolTipTopLevelStyle} contents={<p>{contents}</p>}>
      {children}
    </Tooltip>
  );
}

const toolTipTopLevelStyle = {
  zIndex: 6,
};
