/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import _ from 'lodash';
import React, { memo } from 'react';

import { Select, SelectProps } from '@cimpress/react-components';

import './routedToTypeSelect.scss';

const StyledTypeSelect = styled(Select)`
  width: 200px;
  grid-area: result-type;
`;

const options = [
  { label: 'Sku Code', value: 'skuCode' },
  { label: 'Fulfiller ID', value: 'fulfillerId' },
];

export default memo(
  function RoutedToTypeSelect(props: SelectProps) {
    const selectedValue = _.find(options, (option) => props.value === option.value) || '';
    return (
      <div style={{ gridArea: 'result-type' }}>
        <StyledTypeSelect
          {...props}
          options={options}
          value={selectedValue}
          selectedSelect={Select}
          classNamePrefix={'routed-to'}
        />
      </div>
    );
  },
  (prev, next) => prev.value === next.value,
);
