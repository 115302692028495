/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

export default function ConfigurationSettings({ children }: { children: React.ReactNode }) {
  return (
    <div css={containerStyles}>
      <h4 css={headerStyles}>Configuration settings</h4>
      <div css={innerDivStyles}>{children}</div>
    </div>
  );
}

const containerStyles = css`
  margin-top: -16px;
  margin-left: 16px;
`;

const headerStyles = css`
  margin-bottom: 16px;
`;

const innerDivStyles = css`
  display: flex;
  gap: 10px;
`;
