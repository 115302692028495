/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import _ from 'lodash';
import React, { ForwardedRef, forwardRef, useState } from 'react';
import { RawNodeDatum, default as Tree } from 'react-d3-tree';
import { v4 as uuid } from 'uuid';

import { colors } from '@cimpress/react-components';

import { SMALL_FONT } from '../../constants/stylingConstants';
import { WorkingRoutingConfigurationNode, WorkingRoutingConfigurationV3 } from '../../types';
import NewNode from './AddNodeButton';
// The separate stylesheet is needed to alter the svg path properties
import './style.css';

const NodeTree = forwardRef(function NodeTree(
  {
    onNodeClick,
    workingConfiguration,
    setWorkingConfiguration,
    translate,
    tree,
    zoom,
    minZoom,
    maxZoom,
  }: {
    onNodeClick: (e: HTMLElement) => void;
    workingConfiguration: WorkingRoutingConfigurationV3;
    setWorkingConfiguration: (r: WorkingRoutingConfigurationV3) => void;
    translate: { x: number; y: number };
    tree: RawNodeDatum;
    zoom: number;
    minZoom: number;
    maxZoom: number;
  },
  ref: ForwardedRef<HTMLDivElement>,
) {
  const treeContainerStyles = css`
    border: 1px solid ${colors.slate};
    color: ${colors.coal};
    font-family:
      Open Sans,
      San Francisco,
      Segoe UI,
      Helvetica Neue,
      Arial,
      sans-serif;
    height: 55vh;
    margin-left: 16px;
    width: calc(100svw - 350px);
  `;

  const [selectedElementId, setSelectedElementId] = useState('');

  const nodeSize = { x: 200, y: 150 };

  // We place the foreignObject, which is an HTML element,
  // on top of the actual tree node, which is just an SVG element;
  // hence, the x and y offset
  const foreignObjectProps = { width: nodeSize.x, height: 100, x: -100, y: -15 };

  const addNode = (addButton: HTMLButtonElement) => {
    const parentNodeIndex = _.findIndex(
      workingConfiguration.nodes,
      (node) => node.id === addButton.getAttribute('data-parent-id'),
    );

    if (parentNodeIndex < 0) {
      return;
    }

    const newNode: WorkingRoutingConfigurationNode = {
      id: uuid(),
      name: `New Node ${workingConfiguration.nodes.length + 1}`,
    };

    const routingConfigurationWithEdits = _.cloneDeep(workingConfiguration);

    routingConfigurationWithEdits.nodes[parentNodeIndex].defaultNextNodeId = newNode.id;
    routingConfigurationWithEdits.nodes.push(newNode);
    setWorkingConfiguration(routingConfigurationWithEdits);
  };

  return (
    <div css={treeContainerStyles} ref={ref}>
      {tree && (
        <Tree
          data={tree}
          translate={translate}
          nodeSize={nodeSize}
          collapsible={false}
          hasInteractiveNodes={false}
          renderCustomNodeElement={(rd3tProps) =>
            renderNode({
              ...rd3tProps,
              onClick: onNodeClick,
              foreignObjectProps,
              selectedElementId,
              setSelectedElementId,
              addNode,
            })
          }
          orientation="vertical"
          pathFunc="step"
          scaleExtent={{ min: minZoom, max: maxZoom }}
          zoom={zoom}
        />
      )}
    </div>
  );
});

export default NodeTree;

const renderNode = ({
  onClick,
  nodeDatum,
  foreignObjectProps,
  selectedElementId,
  setSelectedElementId,
  addNode,
}) => {
  if (nodeDatum.attributes?.isPlaceholder) {
    return (
      <g>
        <foreignObject height={50} width={50} x={-25} y={-15}>
          <NewNode
            parentId={nodeDatum.attributes?.parentId}
            onClick={(e) => addNode(e.currentTarget)}
          />
        </foreignObject>
      </g>
    );
  }

  const onClickNode = (e) => {
    onClick(e.currentTarget);
    setSelectedElementId(e.currentTarget.getAttribute('data-id'));
  };

  const nodeStyle =
    nodeDatum.attributes?.id?.toString() === selectedElementId ? selectedStyles : unSelectedStyles;
  return (
    <g>
      {/* `foreignObject` requires width & height to be explicitly set. */}
      <foreignObject
        {...foreignObjectProps}
        onClick={onClickNode}
        data-id={nodeDatum.attributes.id}
      >
        <div css={nodeStyle}>
          <p css={nameStyles}>{nodeDatum.name}</p>
          <p css={descriptionStyles}>{nodeDatum.attributes.description}</p>
        </div>
      </foreignObject>
    </g>
  );
};

const selectedStyles = css`
  background-color: ${colors.sky.base};
  border: 2px solid ${colors.sky.darkest};
  border-radius: 6px;
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.1),
    0 2px 4px 0 rgba(0, 0, 0, 0.15);
  text-align: center;
`;

const unSelectedStyles = css`
  background-color: ${colors.horizon};
  border: 2px solid ${colors.sky.darker};
  border-radius: 6px;
  text-align: center;
`;

const nameStyles = css`
  border-bottom: 1px solid ${colors.sky.darker};
  font-size: 1em;
`;

const descriptionStyles = css`
  color: ${colors.shale};
  font-size: ${SMALL_FONT};
`;
