import _ from 'lodash';
import React from 'react';
import CreatableSelect from 'react-select/creatable';

import { SelectWrapper } from '@cimpress/react-components';

import { DEFAULT_INPUT_HEIGHT } from '../../constants/stylingConstants';
import { DisplayValue } from '../../types';
// Access react-select inner component styles
import './styledArrayInputField.scss';

type ArrayInputFieldPropsOverride = Omit<React.ComponentProps<typeof SelectWrapper>, 'size'> & {
  inputStyles?: Record<string, string>;
  size?: 'xs' | 'sm' | 'md' | 'lg';
};

type StyledArrayInputFieldProps = {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  showDropdown?: boolean;
  value?: DisplayValue<string>[];
};

const StyledArrayInputField: React.FC<
  StyledArrayInputFieldProps & ArrayInputFieldPropsOverride
> = ({ size = 'md', ...props }) => {
  let width = 200;
  if (size) {
    switch (size) {
      case 'lg':
        width = 300;
        break;
      case 'md':
        width = 200;
        break;
      case 'sm':
        width = 175;
        break;
      case 'xs':
        width = 150;
        break;
    }
  }

  // Unfortunately must be passed as a raw object
  const inputStyles = _.merge(
    {
      height: DEFAULT_INPUT_HEIGHT,
      width: `${width}px`,
    },
    props.inputStyles,
  );

  return (
    <SelectWrapper
      {...props}
      selectedSelect={CreatableSelect}
      isMulti={_.isUndefined(props.isMulti) ? true : props.isMulti}
      inputStyle={inputStyles}
      value={props.value}
      classNamePrefix={'array'}
      components={{ DropdownIndicator: null }}
    />
  );
};

export default StyledArrayInputField;
