/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import React from 'react';

import { Select } from '@cimpress/react-components';

// Access react-select inner component styles
import './conditionGroupNextNodeSelect.scss';

const StyledConditionGroupNextNodeSelect = styled(Select)`
  max-width: 300px;
  min-width: 100px;
  margin-bottom: -18px;
`;

export default function ConditionGroupNextNodeSelect(props) {
  return (
    <StyledConditionGroupNextNodeSelect {...props} classNamePrefix={'condition-group-next-node'} />
  );
}
