import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import { ACTION_CONFIGURATIONS } from '../constants/actionConfigurations';
import { CONDITION_CONFIGURATIONS } from '../constants/conditionConfigurations';
import {
  Action,
  ActionOperator,
  ConditionConfiguration,
  FactIdentifiers,
  IfThen,
  IfThenRuleCondition,
  IfThenRuleConditionWithId,
  IfThenWithConditionIds,
  OnHoldAction,
  Operator,
  RoutingConfigurationNode,
  RoutingConfigurationV3,
  WorkingRoutingConfigurationV3,
} from '../types';
import { parseConditionJoiner } from './parseConditionJoiner';

export const removeDanglingIfThenReferences = (
  configuration: WorkingRoutingConfigurationV3,
  ifThenIds: string[],
) => {
  for (const node of configuration.nodes) {
    for (const ifThen of node.ifThens || []) {
      const joiner = parseConditionJoiner(ifThen);
      for (const condition of ifThen?.rule?.conditions?.[joiner] || []) {
        if (condition.fact === FactIdentifiers.successfulIfThens) {
          condition.value = _.filter(
            condition.value,
            (ifThenId) => ifThenIds.indexOf(ifThenId) >= 0,
          );
        }
      }
    }
  }
};

export function attributesForAction(action: Action): ConditionConfiguration {
  if (action.type === 'alsoRoutesLegacy') {
    // This being excluded from the action configurations constant
    // means that this is effectively read-only - you cannot select it
    // from the choices available in the UI.
    return {
      allowedOperators: [],
      parameterIdentifier: 'alsoRoutesLegacy',
      parameterLabel: 'Also Routes (Legacy)',
      path: '',
      fact: FactIdentifiers.orderContext,
      allowedValues: [],
      requiresTextInput: false,
      inputType: 'text',
    };
  }

  if (action.type === 'onHold') {
    // This being excluded from the action configurations constant
    // means that this is effectively read-only - you cannot select it
    // from the choices available in the UI.
    return {
      allowedOperators: [],
      parameterIdentifier: 'onHold',
      parameterLabel: 'Set On Hold',
      path: '',
      fact: FactIdentifiers.orderContext,
      allowedValues: [],
      requiresTextInput: false,
      inputType: 'text',
    };
  }

  const actionConfig = _.find(Object.values(ACTION_CONFIGURATIONS), (a) => a.path === action.path)!;
  if (action.type === 'filter') {
    return {
      ...actionConfig,
      allowedOperators: _.filter(
        actionConfig.allowedOperators,
        (ao) => ao !== ActionOperator.valueInDictionary,
      ),
    };
  }
  return actionConfig;
}

export function getConditionConfiguration(condition: IfThenRuleConditionWithId) {
  let uiAttribute: ConditionConfiguration = {
    allowedOperators: [],
    allowedValues: [],
    requiresTextInput: false,
    inputType: 'text',
    parameterIdentifier: '',
    parameterLabel: '',
    hideOperatorSelect: false,
    hideSpanContainingIs: false,
    disableOperatorSelect: false,
    path: '',
    fact: undefined,
    valueLabel: '',
    defaultValue: '',
    comparisonValue: undefined,
  };

  if (!condition) {
    return uiAttribute;
  }

  if (condition.fact === '') {
    uiAttribute = CONDITION_CONFIGURATIONS.blank;
  } else if (condition.fact === FactIdentifiers.successfulIfThens) {
    uiAttribute = CONDITION_CONFIGURATIONS.successfulIfThens;
  } else if (condition.fact === FactIdentifiers.options) {
    // Find the condition attribute for allAre/anyAre/noneAre for options
    uiAttribute = matchValuePathToFulfillmentOptionsBasedConfiguration(condition.value)!;
    // Find the condition attribute for custom source/path
  } else if (condition.customSource) {
    uiAttribute = CONDITION_CONFIGURATIONS.custom;
    // Find the condition attribute by path
  } else {
    // Match by path and allowed operator
    const matchedAttribute = _.find(
      Object.values(CONDITION_CONFIGURATIONS),
      (conditionConfiguration) =>
        condition.path === conditionConfiguration.path &&
        _.indexOf(conditionConfiguration.allowedOperators, condition.operator) >= 0,
    );

    if (matchedAttribute) {
      uiAttribute = matchedAttribute;
    }
  }

  let comparisonValue = condition.value;

  if (condition.operator === Operator.orderedWithAnyOf) {
    comparisonValue = Array.isArray(condition.value) ? condition.value : [];
  }

  // Some operators require the value field to be an object
  // that includes other information, like units
  if (_.isObjectLike(comparisonValue) && 'value' in comparisonValue) {
    comparisonValue = (comparisonValue as any).value;
  }

  return {
    ...uiAttribute,
    acceptsCommaSeparatedList: operatorAcceptsCommaSeparatedList(condition.operator),
    comparisonValue,
  };
}

export function getFactFromIfThenRuleCondition(condition: IfThenRuleConditionWithId) {
  if (condition.customSource) {
    return FactIdentifiers.orderContext;
  }
  if (
    condition.fact === FactIdentifiers.successfulIfThens &&
    condition.operator === Operator.containsNone
  ) {
    return FactIdentifiers.successfulIfThens;
  }

  if (condition.path) {
    return condition.fact
      ? condition.fact
      : _.find(
          Object.values(CONDITION_CONFIGURATIONS),
          (attribute) => condition.path === attribute.path,
        )?.fact;
  }

  if (condition.value?.path) {
    return matchValuePathToFulfillmentOptionsBasedConfiguration(condition.value)?.fact;
  }

  return FactIdentifiers.options;
}

// Condition configurations related to fulfillment options have no top-level path,
// and instead have a path inside of their value object.
export function matchValuePathToFulfillmentOptionsBasedConfiguration(value: {
  path: string;
  filterPath: string;
  filterValue: string;
}) {
  return _.find(
    Object.values(CONDITION_CONFIGURATIONS),
    (attribute) => value.path === attribute.defaultValue?.path,
  );
}

export function operatorAcceptsCommaSeparatedList(operator?: Operator | ActionOperator) {
  if (!operator) {
    return false;
  }
  return (
    [
      Operator.in,
      Operator.notIn,
      Operator.orderedWithAnyOf,
      ActionOperator.in,
      ActionOperator.notIn,
    ].indexOf(operator) >= 0
  );
}

export function addIdsToIfThens(ifThens: IfThen[]) {
  return _.map(ifThens, (ifThen) => addIdToSingleIfThen(ifThen));
}

function addIdToSingleIfThen(ifThen: IfThen) {
  return {
    ...ifThen,
    rule: {
      ...ifThen.rule,
      conditions: {
        [parseConditionJoiner(ifThen)]: ifThen.rule.conditions![parseConditionJoiner(ifThen)]!.map(
          (condition) => ({
            ...condition,
            id: (condition as any).id ?? uuid(),
          }),
        ),
      },
    },
  };
}

export function formatSingleIfThenForBackend(ifThen: IfThenWithConditionIds): IfThen {
  const action = ifThen.action;
  const joiner = parseConditionJoiner(ifThen);
  const formattedIfThen: IfThen = {
    ..._.omit(ifThen, 'action'),
    rule: {
      ...ifThen.rule,
      conditions: {
        [joiner]: ifThen.rule.conditions![joiner]!.map((condition: IfThenRuleConditionWithId) =>
          normalizeConditionForBackend(condition),
        ),
      },
    },
  };
  if (action) {
    formattedIfThen.actionId = action.id;
  }

  return formattedIfThen;
}

export function normalizeConditionForBackend(
  condition: IfThenRuleConditionWithId,
): IfThenRuleCondition {
  let formattedCondition = condition;

  if (condition.customSource) {
    formattedCondition = normalizeCustomSourceForBackend(formattedCondition);
  }

  if (condition.path === CONDITION_CONFIGURATIONS.cmrd.path) {
    formattedCondition = {
      ...formattedCondition,
      operator: Operator.cmrd,
      value: {
        days: formattedCondition.value,
        operator: formattedCondition.operator,
      },
    };
  }

  return _.omit(formattedCondition, 'id', 'customSource', 'customPath');
}

export function normalizeCustomSourceForBackend(condition: IfThenRuleConditionWithId) {
  let path = condition.customSource!;
  if (condition.customPath) {
    path += `.${condition.customPath}`;
  }

  return {
    ...condition,
    path,
  };
}

export function formatActionForBackend(action: Action) {
  if (action.type === 'alsoRoutesLegacy') {
    return action;
  }
  if (action.type === 'onHold') {
    return {
      id: action.id,
      type: action.type,
    } as OnHoldAction;
  }

  const clonedAction = { ...action };
  if (action.operator === ActionOperator.valueInDictionary) {
    clonedAction.value = _.reduce(
      _.entries(action.value),
      (dict, [key, value]) => {
        if (_.isObject(value) && !_.isUndefined((value as any)?.value)) {
          dict[key] = (value as any).value;
        }
        return dict;
      },
      {} as Record<string, number>,
    );
  }

  return clonedAction;
}

export function formatActionForFrontend(action: Action) {
  if (action.type === 'alsoRoutesLegacy') {
    return action;
  }
  if (action.type === 'onHold') {
    return action;
  }

  const clonedAction = { ...action };
  if (action.operator === ActionOperator.valueInDictionary) {
    clonedAction.value = _.reduce(
      _.entries(action.value),
      (dict, [key, value], index) => {
        if (_.isNumber(value) && !_.isUndefined(value)) {
          dict[key] = {
            value,
            index,
          };
        }
        return dict;
      },
      {} as Record<string, any>,
    );
  }

  return clonedAction;
}

export function convertWorkingConfigToDbConfig({
  workingConfiguration,
  baseConfiguration,
}: {
  workingConfiguration: WorkingRoutingConfigurationV3;
  baseConfiguration: RoutingConfigurationV3;
}): RoutingConfigurationV3 {
  const nodes: RoutingConfigurationNode[] = [];
  const ifThens: IfThen[] = [];
  const actions: Action[] = [];

  for (const node of workingConfiguration.nodes) {
    const clonedNode = { ...node };
    const ifThenIds: string[] = [];

    if (clonedNode.defaultAction) {
      actions.push(formatActionForBackend(clonedNode.defaultAction));
      clonedNode.defaultActionId = clonedNode.defaultAction!.id;
    }
    for (const ifThen of clonedNode.ifThens || []) {
      ifThens.push(formatSingleIfThenForBackend(ifThen));
      ifThenIds.push(ifThen.id);
      if (ifThen.action) {
        actions.push(formatActionForBackend(ifThen.action));
      }
    }

    const formattedNode = _.omit(clonedNode, ['ifThens', 'defaultAction']);
    nodes.push({
      ...formattedNode,
      ifThenIds,
    } as RoutingConfigurationNode);
  }

  return {
    ...baseConfiguration,
    description: workingConfiguration.description,
    startingNodeId: workingConfiguration.startingNodeId,
    selectionStrategy: workingConfiguration.selectionStrategy,
    nodes,
    ifThens,
    actions,
    nativeV3: true,
  };
}
