/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from '@emotion/react';

import { Action, ActionOperator, ActionV3 } from '../../types';

export function getActionGridStyles({ action }: { action: Action }): SerializedStyles | undefined {
  if (action.type === 'alsoRoutesLegacy') {
    return undefined; // TODO: Replace with better
  }
  if (action.type === 'onHold') {
    return gridStylesOnHold;
  }

  const actionV3 = action as ActionV3;

  if (actionV3.type === 'filter') {
    if (actionV3.operator === ActionOperator.margin) {
      return gridStylesMarginFilter;
    }
    if (actionV3.operator === ActionOperator.valueInDictionary) {
      return gridStylesFilterRankedTable;
    }
    return gridStylesStandardFilter;
  } else if (actionV3.type === 'sort') {
    if (actionV3.operator === ActionOperator.margin) {
      return gridStylesMarginSorter;
    }
    if (actionV3.operator === ActionOperator.valueInDictionary) {
      return gridStylesSorterRankedTable;
    }
    return gridStylesStandardSorter;
  }
}

const gridStylesOnHold = css`
  column-gap: 10px;
  display: grid;
  grid-template-columns: 210px auto auto auto auto 40px;
  grid-template-rows: 52px 52px;
  grid-template-areas:
    'sort-or-filter details . . . delete'
    'on-hold value . . . .';
`;

const gridStylesStandardSorter = css`
  column-gap: 10px;
  display: grid;

  grid-template-columns: 210px 200px 200px 250px 52px auto 40px;
  grid-template-rows: 52px 52px;
  grid-template-areas:
    'sort-or-filter parameter operator direction price-tooltip . delete'
    'on-hold value . . . . .';

  @media (max-width: 1010px) {
    grid-template-columns: minmax(150px, 210px) minmax(100px, 200px) auto 40px;
    grid-template-rows: 52px 52px 52px;
    grid-template-areas:
      'sort-or-filter parameter direction . delete'
      'on-hold operator value price-tooltip .';
  }
`;

const gridStylesSorterRankedTable = css`
  column-gap: 10px;
  display: grid;

  grid-template-columns: 210px 200px 200px 250px auto 40px;
  grid-template-rows: 52px 52px;
  grid-template-areas:
    'sort-or-filter parameter operator direction . delete'
    'on-hold . . . . .'
    'table . . . . .';

  @media (max-width: 820px) {
    grid-template-columns: minmax(150px, 210px) minmax(100px, 200px) auto 40px;
    grid-template-areas:
      'sort-or-filter parameter . delete'
      'on-hold operator direction .'
      'table . . .';
  }
`;

const gridStylesFilterRankedTable = css`
  column-gap: 10px;
  display: grid;

  grid-template-columns: 210px 200px 200px 90px auto 40px;
  grid-template-areas:
    'sort-or-filter parameter operator cause cause delete'
    'table table . . . .';

  @media (max-width: 820px) {
    grid-template-columns: minmax(150px, 210px) minmax(100px, 200px) auto 40px;
    grid-template-areas:
      'sort-or-filter parameter . delete'
      'operator cause cause .'
      'table table . .';
  }
`;

const gridStylesStandardFilter = css`
  column-gap: 10px;
  display: grid;

  grid-template-columns: 210px 200px 200px 150px auto 40px;
  grid-template-areas:
    'sort-or-filter parameter operator value . delete'
    'cause cause cause . . .';

  @media (max-width: 1020px) {
    grid-template-columns: minmax(150px, 210px) minmax(100px, 200px) auto auto 40px;
    grid-template-areas:
      'sort-or-filter parameter . . delete'
      'operator value cause cause  .';
  }
`;

const gridStylesMarginFilter = css`
  column-gap: 10px;
  display: grid;

  grid-template-columns: 210px 200px auto 40px;
  grid-template-areas:
    'sort-or-filter parameter . delete'
    'fulfillers percentage cause .';
`;

const gridStylesMarginSorter = css`
  column-gap: 10px;
  display: grid;

  grid-template-columns: 210px 200px 250px auto 40px;
  grid-template-areas:
    'sort-or-filter parameter direction . delete'
    'on-hold fulfillers percentage . .';
`;
