/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from '@emotion/react';
import React from 'react';

import Icon from '@cimpress-technology/react-streamline-icons/lib/IconSynchronizeArrowsAlt';

import { DEFAULT_INPUT_HEIGHT } from '../../constants/stylingConstants';

export default function SynchronizeArrowsAltIcon({
  size = 'lg',
  styles,
}: {
  size?: string;
  styles?: SerializedStyles;
}) {
  const iconStyles = css`
    grid-area: synchronize-arrow;
    justify-self: end;
    padding-top: 2px;

    height: ${DEFAULT_INPUT_HEIGHT};
    ${styles || {}};
  `;

  return <Icon size={size as any} css={iconStyles} />;
}
