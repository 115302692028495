/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { Button } from '@cimpress/react-components';

import { SortDirection } from '../../types';

export default function SortDirectionComponent({
  sortDirection,
  setSortDirection,
}: {
  sortDirection: SortDirection;
  setSortDirection: (sortDirection: SortDirection) => void;
}) {
  return (
    <div css={flexDivStyles}>
      <Button
        variant={sortDirection === 'asc' ? 'primary' : 'default'}
        onClick={() => setSortDirection('asc')}
        css={buttonStyles}
      >
        Ascending
      </Button>
      <Button
        variant={sortDirection === 'desc' ? 'primary' : 'default'}
        onClick={() => setSortDirection('desc')}
        css={buttonStyles}
      >
        Descending
      </Button>
    </div>
  );
}

const flexDivStyles = css`
  grid-area: direction;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  height: 52px;
`;

const buttonStyles = css`
  height: 52px;
`;
