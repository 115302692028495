import { ActionOperator, ConditionConfiguration, FactIdentifiers } from '../types';

const booleanOperators = [ActionOperator.equal, ActionOperator.notEqual];

const numericOperators = [
  ActionOperator.equal,
  ActionOperator.notEqual,
  ActionOperator.greaterThan,
  ActionOperator.greaterThanInclusive,
  ActionOperator.lessThan,
  ActionOperator.lessThanInclusive,
];

export const ACTION_CONFIGURATIONS: Record<string, ConditionConfiguration> = {
  price: {
    allowedOperators: [ActionOperator.valueAtPath, ...numericOperators],
    parameterIdentifier: 'price',
    parameterLabel: 'Price',
    path: '_embedded.deliveryFeasibility.price.combined.total',
    fact: FactIdentifiers.options,
    allowedValues: [],
    requiresTextInput: true,
    inputType: 'number',
    shouldTruncateNumberValue: false,
    defaultOperator: ActionOperator.valueAtPath,
  },
  priceIncludingCostOfDelay: {
    allowedOperators: [ActionOperator.valueAtPath, ...numericOperators],
    parameterIdentifier: 'priceIncludingCostOfDelay',
    parameterLabel: 'Price including cost of delay',
    path: 'priceIncludingCostOfDelay',
    fact: FactIdentifiers.options,
    allowedValues: [],
    requiresTextInput: true,
    inputType: 'number',
    shouldTruncateNumberValue: false,
    defaultOperator: ActionOperator.valueAtPath,
  },
  priceAssumingInHouseCost: {
    allowedOperators: [ActionOperator.valueAtPath, ...numericOperators],
    parameterIdentifier: 'priceIncludingAssumedInHouseCost',
    parameterLabel: 'Price assuming in-house cost',
    path: 'priceIncludingAssumedInHouseCost',
    fact: FactIdentifiers.options,
    allowedValues: [],
    requiresTextInput: true,
    undefinedAllowed: true,
    inputType: 'number',
    shouldTruncateNumberValue: false,
    defaultOperator: ActionOperator.valueAtPath,
  },
  estimatedArrivalDate: {
    allowedOperators: [ActionOperator.valueAtPath],
    parameterIdentifier: 'estimatedArrivalDate',
    parameterLabel: 'Estimated arrival date',
    path: '_embedded.shipmentPlan[0].estimatedArrivalDate',
    fact: FactIdentifiers.options,
    allowedValues: [],
    isDateRelated: true,
    requiresTextInput: false,
    defaultOperator: ActionOperator.valueAtPath,
  },
  // TODO: Depricate/delete this after checking if anything currently uses this
  passedMarginFilter: {
    allowedOperators: [ActionOperator.valueAtPath, ...booleanOperators],
    parameterIdentifier: 'passedMarginFilter',
    parameterLabel: 'Passed margin filter',
    path: 'marginFilter.passedMarginFilter',
    fact: FactIdentifiers.options,
    allowedValues: [true, false],
    requiresTextInput: false,
    defaultOperator: ActionOperator.valueAtPath,
  },
  metMinCapacity: {
    allowedOperators: [ActionOperator.valueAtPath, ...booleanOperators],
    parameterIdentifier: 'metMinCapacity',
    parameterLabel: 'Met min capacity',
    path: 'capacityAvailability.metMinCapacity',
    fact: FactIdentifiers.options,
    allowedValues: [true, false],
    requiresTextInput: false,
    defaultOperator: ActionOperator.valueAtPath,
  },
  lateArrival: {
    allowedOperators: [ActionOperator.valueAtPath, ...booleanOperators],
    parameterIdentifier: 'lateArrival',
    parameterLabel: 'Late arrival',
    path: '_embedded.deliveryFeasibility.lateArrival',
    fact: FactIdentifiers.options,
    allowedValues: [true, false],
    requiresTextInput: false,
    defaultOperator: ActionOperator.valueAtPath,
  },
  fulfillerId: {
    allowedOperators: [ActionOperator.valueInDictionary, ActionOperator.in, ActionOperator.notIn],
    parameterIdentifier: 'fulfillerId',
    parameterLabel: 'Fulfiller ID',
    path: 'fulfillerId',
    fact: FactIdentifiers.options,
    allowedValues: [],
    requiresTextInput: true,
    inputType: 'text',
    defaultOperator: ActionOperator.valueInDictionary,
    defaultValue: '',
    selectionSource: 'fulfillers',
    valueInTableSettings: {
      inputName: 'FulfillerId',
    },
  },
  outOfStock: {
    allowedOperators: [ActionOperator.valueAtPath, ...booleanOperators],
    parameterIdentifier: 'outOfStock',
    parameterLabel: 'Is out of stock',
    path: 'isOutOfStock',
    fact: FactIdentifiers.options,
    allowedValues: [true, false],
    requiresTextInput: false,
    defaultOperator: ActionOperator.equal,
  },
  skuCode: {
    allowedOperators: [
      ...booleanOperators,
      ActionOperator.in,
      ActionOperator.notIn,
      ActionOperator.valueInDictionary,
    ],
    parameterIdentifier: 'skuCode',
    parameterLabel: 'SKU Code',
    path: 'mcpSku',
    fact: FactIdentifiers.options,
    allowedValues: [],
    requiresTextInput: true,
    inputType: 'text',
    defaultOperator: ActionOperator.equal,
    selectionSource: 'custom',
    valueInTableSettings: {
      inputName: 'SkuCode',
    },
  },
  margin: {
    allowedOperators: [ActionOperator.margin],
    parameterIdentifier: 'margin',
    parameterLabel: 'Margin',
    path: '',
    fact: FactIdentifiers.options,
    allowedValues: [],
    requiresTextInput: false,
    inputType: 'number',
    hideOperatorSelect: true,
    defaultOperator: ActionOperator.margin,
  },
  fulfillmentProcess: {
    allowedOperators: [
      ActionOperator.equal,
      ActionOperator.notEqual,
      ActionOperator.in,
      ActionOperator.notIn,
      ActionOperator.valueInDictionary,
    ],
    parameterIdentifier: 'fulfillmentProcess',
    parameterLabel: 'Fulfillment process',
    path: '_links.baseFulfillmentConfiguration.name',
    fact: FactIdentifiers.options,
    allowedValues: [],
    requiresTextInput: true,
    inputType: 'text',
    defaultOperator: ActionOperator.equal,
    selectionSource: 'custom',
    valueInTableSettings: {
      inputName: 'Process Id',
    },
  },
};
