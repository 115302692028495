/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Select, SelectProps } from '@cimpress/react-components';

// Access react-select inner component styles
import './parameterSelect.scss';

const StyledParameterSelect = styled(Select)`
  min-width: 100px;
  max-width: 200px;
`;

const ParameterSelect: React.FC<SelectProps> = (props) => {
  return (
    <div css={parameterWrapperProps}>
      <StyledParameterSelect {...props} classNamePrefix={'parameter'} />
    </div>
  );
};

export default ParameterSelect;

const parameterWrapperProps = css`
  grid-area: parameter;
`;
