import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import _ from 'lodash';

import {
  RoutingConfigurationV3,
  WorkingRoutingConfigurationNode,
  WorkingRoutingConfigurationV3,
} from '../../types';
import { fetchRoutingConfigByBuyerAndSku, fetchRoutingConfigById } from './actions';

export interface SelectedConfigurationState {
  skuCode?: string;
  routingConfiguration: RoutingConfigurationV3 | null;
  workingConfiguration: WorkingRoutingConfigurationV3 | null;
  baseWorkingConfigurationSnapshot: WorkingRoutingConfigurationV3 | null;
  selectedNode: WorkingRoutingConfigurationNode | null;
  workingNode: WorkingRoutingConfigurationNode | null;
  etag: string;
  loading: boolean;
}

const initialState: SelectedConfigurationState = {
  routingConfiguration: null,
  workingConfiguration: null,
  baseWorkingConfigurationSnapshot: null,
  selectedNode: null,
  workingNode: null,
  etag: '',
  loading: false,
};

const selectedConfigurationSlice = createSlice({
  name: 'selectedConfiguration',
  initialState,
  reducers: {
    setSkuCode(state, action: PayloadAction<string>) {
      state.skuCode = action.payload;
    },
    setRoutingConfiguration(state, action: PayloadAction<RoutingConfigurationV3 | null>) {
      state.routingConfiguration = action.payload;
      state.loading = false;
    },
    resetRoutingConfiguration(state) {
      state.routingConfiguration = null;
    },
    setEtag(state, action: PayloadAction<string>) {
      state.etag = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setWorkingConfiguration(state, action: PayloadAction<WorkingRoutingConfigurationV3 | null>) {
      state.workingConfiguration = action.payload;
    },
    setBaseWorkingConfigurationSnapshot(
      state,
      action: PayloadAction<WorkingRoutingConfigurationV3 | null>,
    ) {
      state.baseWorkingConfigurationSnapshot = action.payload;
    },
    setSelectedNode(state, action: PayloadAction<WorkingRoutingConfigurationNode | null>) {
      state.selectedNode = action.payload;
    },
    setWorkingNode(state, action: PayloadAction<WorkingRoutingConfigurationNode | null>) {
      state.workingNode = action.payload;
    },
    setConfigurationStateFromEvaluationRecord(state, action: PayloadAction<Record<string, any>>) {
      const configurationRecord = _.get(action.payload, 'configurationState');
      state.routingConfiguration = _.get(configurationRecord, 'routingConfiguration');
      state.workingConfiguration = _.get(configurationRecord, 'workingConfiguration');
      state.baseWorkingConfigurationSnapshot = _.get(
        configurationRecord,
        'baseWorkingConfigurationSnapshot',
      );
      state.skuCode = _.get(configurationRecord, 'skuCode');
      state.etag = _.get(configurationRecord, 'etag');
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(fetchRoutingConfigByBuyerAndSku.pending, fetchRoutingConfigById.pending),
        (state) => {
          state.loading = true;
        },
      )
      .addMatcher(
        isAnyOf(fetchRoutingConfigByBuyerAndSku.fulfilled, fetchRoutingConfigById.fulfilled),
        (state, action) => {
          state.routingConfiguration = action.payload!.configuration;
          // Ensure that these have different object references
          state.workingConfiguration = { ...action.payload!.hydratedConfiguration! };
          state.baseWorkingConfigurationSnapshot = { ...action.payload!.hydratedConfiguration! };
          state.etag = action.payload!.etag;
          state.loading = false;
          state.skuCode = action.payload!.skuCode || state.skuCode;
        },
      )
      .addMatcher(
        isAnyOf(fetchRoutingConfigByBuyerAndSku.rejected, fetchRoutingConfigById.rejected),
        (state) => {
          state.loading = false;
        },
      );
  },
});

export const {
  setSkuCode,
  setRoutingConfiguration,
  resetRoutingConfiguration,
  setEtag,
  setLoading,
  setWorkingConfiguration,
  setBaseWorkingConfigurationSnapshot,
  setSelectedNode,
  setWorkingNode,
  setConfigurationStateFromEvaluationRecord,
} = selectedConfigurationSlice.actions;
export default selectedConfigurationSlice.reducer;
