/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { Button } from '@cimpress/react-components';

import { DEFAULT_INPUT_HEIGHT } from '../../constants/stylingConstants';

export default function ActionTypeSelection({
  actionType,
  setActionType,
}: {
  actionType: 'sort' | 'filter' | 'onHold';
  setActionType: (newActionType: 'sort' | 'filter' | 'onHold') => void;
}) {
  return (
    <div css={containingDivStyle}>
      <div css={flexDivStylesSortFilter}>
        <Button
          variant={actionType === 'sort' ? 'primary' : 'default'}
          onClick={() => setActionType('sort')}
          style={halfWidthButton}
        >
          Sort by
        </Button>
        <Button
          variant={actionType === 'filter' ? 'primary' : 'default'}
          onClick={() => setActionType('filter')}
          style={halfWidthButton}
        >
          Filter by
        </Button>
      </div>
      <div css={flexDivStylesOnHold}>
        <Button
          variant={actionType === 'onHold' ? 'primary' : 'default'}
          onClick={() => setActionType('onHold')}
          style={fullWidthButton}
        >
          Set On Hold
        </Button>
      </div>
    </div>
  );
}

const halfWidthButton = {
  height: '52px',
  width: '50%',
};

const fullWidthButton = {
  height: '52px',
  width: '100%',
};

const containingDivStyle = css`
  margin-bottom: 10px;
`;

const flexDivStylesOnHold = css`
  grid-area: on-hold;
  align-items: center;
  display: flex;
  height: ${DEFAULT_INPUT_HEIGHT};
`;

const flexDivStylesSortFilter = css`
  grid-area: sort-or-filter;
  align-items: center;
  display: flex;
  height: ${DEFAULT_INPUT_HEIGHT};
`;
