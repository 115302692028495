import _ from 'lodash';
import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Button, Copy, Modal, Spinner } from '@cimpress/react-components';

import { setError } from '../../features/alerts/alertContentSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { formatHttpErrorMessage } from '../../utils/formatHttpErrorMessage';
import { uploadTesterState } from '../../utils/storage';
import styles from './shareTesterUrlModal.module.scss';

export default function ShareTesterUrlModal({ show, close }: { show: boolean; close: () => void }) {
  const { pathname, search } = useLocation();
  const { accountId } = useParams();

  const dispatch = useAppDispatch();

  const environment = useAppSelector((state) => state.resourceSearch.environment);
  const testerState = useAppSelector((state) => state.testInput);
  const productConfigurationUrl = useAppSelector(
    (state) => state.productConfiguration.productConfigurationUrl,
  )!;
  const evaluationResults = useAppSelector((state) => state.evaluationResults);
  const routingRequestLink = useAppSelector((state) => state.resourceSearch.routingRequestLink)!;

  const configurationState = useAppSelector((state) => state.selectedConfiguration)!;

  let baseUrl: string;

  if (window.location.href.includes('localhost')) {
    baseUrl = 'localhost:3000';
  } else if (environment === 'prd') {
    baseUrl = 'https://routing-configuration.cimpress.io';
  } else {
    baseUrl = 'https://int-routing-configuration.cimpress.io';
  }

  const [uploadId, setUploadId] = useState<string | undefined>(undefined);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  // Strip off old ID if still there
  let pathNameToUse = pathname.endsWith('evaluate')
    ? pathname
    : _(pathname).split('/').initial().join('/');

  // Note that pathname begins with a forward slash
  let shareableUrl = `${baseUrl}${pathNameToUse}/${uploadId}`;

  if (search.length) {
    shareableUrl += `?${search}`;
  }

  const handleUpload = () => {
    setIsSaving(true);
    uploadTesterState({
      accountId: accountId!,
      environment,
      configurationState,
      productConfigurationUrl,
      testerState,
      routingResults: evaluationResults.results,
      routingRequestLink,
    })
      .then((res) => {
        setUploadId(res.evaluationId);
        setIsSaving(false);
      })
      .catch((err) => {
        setIsSaving(false);
        dispatch(
          setError({
            title: 'An error occurred while saving the tester state',
            messages: formatHttpErrorMessage(err),
          }),
        );
        close();
      });
  };

  const handleClose = () => {
    setUploadId('');
    close();
  };

  return (
    <Modal
      className={styles.modal}
      show={show}
      closeButton={true}
      onRequestHide={handleClose}
      title="Share Tester URL"
      footer={<Button onClick={handleClose}>Okay</Button>}
    >
      <div className={styles.center}>
        {uploadId ? (
          <Copy value={shareableUrl} variant="button" />
        ) : (
          <Button onClick={handleUpload}>Get Shareable URL</Button>
        )}
      </div>
      {isSaving && <Spinner />}
    </Modal>
  );
}
