/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import React from 'react';

import { Select } from '@cimpress/react-components';

import './defaultNextNodeSelect.scss';

const StyledDefaultNextNodeSelect = styled(Select)`
  width: 300px;
`;

export default function DefaultNextNodeSelect(props) {
  return <StyledDefaultNextNodeSelect {...props} classNamePrefix={'default-next-node'} />;
}
