export type DisplayValue<T> = {
  label: string;
  value: T;
};

export type Link = {
  href: string;
};

export type LinkWithName = Link & {
  name: string;
};

export enum SelectionStrategy {
  'random' = 'random',
  'first' = 'first',
}

export type RoutingConfigurationV3 = {
  id: string;
  accountId: string;
  description?: string;
  startingNodeId: string;
  nodes: RoutingConfigurationNode[];
  ifThens: IfThen[];
  actions: Action[];
  createdAt: string;
  createdBy: string;
  modifiedAt?: string;
  modifiedBy?: string;
  version: number;
  isDraft: boolean;
  isDeleted?: boolean;
  selectionStrategy: SelectionStrategy;
  nativeV3?: boolean;
  referenceId?: string;
};

// nativeV3 not included, so that it is never
// a point of difference between working config and snapshot.
export type WorkingRoutingConfigurationV3 = {
  id: string;
  description?: string;
  startingNodeId: string;
  nodes: WorkingRoutingConfigurationNode[];
  selectionStrategy: SelectionStrategy;
  isDraft: boolean;
};

export type RoutingConfigurationResponseV3 = RoutingConfigurationV3 & {
  _links: {
    self: LinkWithName;
  };
};

export type RoutingConfigurationPaginatedResponseV3 = {
  offset?: string;
  count: number;
  total: number;
  _embedded: {
    item: RoutingConfigurationResponseV3[];
  };
  _links: {
    self: LinkWithName;
    next?: LinkWithName;
  };
};

export type RoutingConfigurationNode = {
  id: string;
  name: string;
  description?: string;
  ifThenIds?: string[];
  defaultActionId?: string;
  defaultNextNodeId?: string;
};

export type WorkingRoutingConfigurationNode = {
  id: string;
  name: string;
  description?: string;
  ifThens?: WorkingIfThen[];
  defaultAction?: Action;
  defaultActionId?: string;
  defaultNextNodeId?: string;
  ifThensPassedNextNodeId?: string;
  ifThenContinuation?: boolean;
};

export enum FactIdentifiers {
  'options' = 'options',
  'orderContext' = 'orderContext',
  'successfulIfThens' = 'successfulIfThens',
}

// These are optional because they may be blank in the UI
export type IfThenRuleCondition = {
  fact?: FactIdentifiers | string;
  path?: string;
  operator?: Operator;
  value?: any;
  name?: string;
};

export type IfThenRuleConditionWithId = IfThenRuleCondition & {
  id: string;
  customSource?: string | null;
  customPath?: string | null;
};

export type Rule = {
  name?: string;
  conditions: {
    all?: IfThenRuleCondition[];
    any?: IfThenRuleCondition[];
    not?: IfThenRuleCondition[];
  };
};

export type RuleWithConditionIds = {
  name?: string;
  conditions: {
    all?: IfThenRuleConditionWithId[];
    any?: IfThenRuleConditionWithId[];
    not?: IfThenRuleConditionWithId[];
  };
};

export type IfThen = {
  id: string;
  name?: string;
  description?: string;
  rule: Rule;
  actionId?: string;
  nextNodeId?: string;
};

export type WorkingIfThen = IfThenWithConditionIds & {
  action?: Action;
};

export type IfThenWithConditionIds = IfThen & {
  rule: RuleWithConditionIds;
  action?: Action;
};

export type SortDirection = 'asc' | 'desc';

export enum ActionOperator {
  'in' = 'in',
  'notIn' = 'notIn',
  'equal' = 'equal',
  'notEqual' = 'notEqual',
  'valueAtPath' = 'valueAtPath',
  'valueInDictionary' = 'valueInDictionary',
  'greaterThan' = 'greaterThan',
  'greaterThanInclusive' = 'greaterThanInclusive',
  'lessThan' = 'lessThan',
  'lessThanInclusive' = 'lessThanInclusive',
  'margin' = 'margin',
}

export enum Operator {
  'equal' = 'equal',
  'notEqual' = 'notEqual',
  'greaterThan' = 'greaterThan',
  'greaterThanInclusive' = 'greaterThanInclusive',
  'lessThan' = 'lessThan',
  'lessThanInclusive' = 'lessThanInclusive',
  'in' = 'in',
  'notIn' = 'notIn',
  // custom operators
  'allAre' = 'allAre',
  'noneAre' = 'noneAre',
  'anyAre' = 'anyAre',
  'orderedWithAnyOf' = 'orderedWithAnyOf',
  'dateLess' = 'dateLess',
  'dateLessEqual' = 'dateLessEqual',
  'dateGreater' = 'dateGreater',
  'dateGreaterEqual' = 'dateGreaterEqual',
  'poBox' = 'poBox', // checks address for poBox and returns true/false based on value
  'geocode' = 'geocode',
  'containsNone' = 'containsNone',
  'routedTo' = 'routedTo',
  'cmrd' = 'cmrd',
}

export type SortAction = {
  id: string;
  type: 'sort';
  path: string; // This is a Lodash-style path, NOT json-path
  operator: ActionOperator;
  value?: any;
  direction: SortDirection;
};

export type FilterAction = {
  id: string;
  type: 'filter';
  path: string; // This is a Lodash-style path, NOT json-path
  operator: ActionOperator;
  value?: any;
  cause: string;
};

export type ActionV3 = SortAction | FilterAction;

export type LegacyAlsoRoutesAction = {
  id: string;
  type: 'alsoRoutesLegacy';
  value: {
    orderedSku: string;
    resultSku: string;
    parentResultSku?: string;
  };
};

export type OnHoldAction = {
  id: string;
  type: 'onHold';
};

export type Action = ActionV3 | OnHoldAction | LegacyAlsoRoutesAction;

export type RoutingConfigurationPutBody = {
  id: string;
  description?: string;
  startingNodeId: string;
  nodes: RoutingConfigurationNode[];
  ifThens: IfThen[];
  actions: Action[];
  isDraft?: boolean;
  selectionStrategy: SelectionStrategy;
};

export type RoutingConfigurationPostBody = RoutingConfigurationPutBody & {
  accountId: string;
  referenceId: string;
};

export type SelectionSource = 'fulfillers' | 'custom' | 'merchants';

export type ConditionConfiguration = {
  allowedOperators: (Operator | ActionOperator)[];
  parameterIdentifier: any;
  parameterLabel: string;
  path: string;
  fact?: string;
  allowedValues: any[];
  requiresTextInput: boolean;
  undefinedAllowed?: boolean;
  requiresArrayTextInput?: boolean;
  isDateRelated?: boolean;
  inputType?: 'text' | 'number';
  acceptsCommaSeparatedList?: boolean;
  hideOperatorSelect?: boolean;
  hideSpanContainingIs?: boolean;
  disableOperatorSelect?: boolean;
  defaultOperator?: Operator | ActionOperator;
  defaultValue?: any;
  valueLabel?: string;
  shouldTruncateNumberValue?: boolean;
  lockValueSelectWith?: any;
  selectionSource?: SelectionSource;
  valueInTableSettings?: {
    inputName: string;
  };
  comparisonValue?: any;
};

export type ActionConfiguration = {
  operator: Operator;
  path: string;
  value?: any;
};

export type ComparisonValueOperator = {
  value: Operator;
  label: string;
};

export type UiDictionaryValue = {
  value: string | number;
  index: number;
};
