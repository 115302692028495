import { v4 as uuid } from 'uuid';
import { unzipurl, zipurl } from 'zipurl';

import { SelectedConfigurationState } from '../features/selectedConfiguration/selectedConfigurationSlice';
import { TestInputState } from '../features/testInput/testInputSlice';
import { RoutingResult } from '../services/testRoutingDecision';
import { LinkWithName } from '../types';
import fetchWithAuth from './fetchWithAuth';

export const ENCODED_TESTER_FIELDS = [
  'quantity',
  'merchantId',
  'country',
  'stateOrProvince',
  'postalCode',
  'isPoBox',
  'fulfillmentCaps',
  'orderDate',
  'promisedArrivalDate',
  'overrideValues',
  'items',
  'selectedItem',
  'orderItems',
  'orderItemsAreValid',
  'retrieveFulfillmentOptions',
  'ignoreRealTimeFactors',
  'persistResults',
  'deliveryConstraints',
];

export const DEFAULT_ENCODED_ITEM_FIELDS = [
  'buyerAccountUrl',
  'deliveryConfigurations',
  'deliveryConstraints',
  'destinationAddress',
  'fulfillmentCapabilities',
  'fulfillmentGroupId',
  'globalFulfillerId',
  'itemUrl',
  'localPromisedArrivalDate',
  'mcpSku',
  'merchantId',
  'quantity',
  'skuCode',
];

const baseUrl = process.env.REACT_APP_ORCA_URL;

type EvaluationResponse = {
  _embedded: {
    evaluationState: {
      id: string;
      createdTime: string;
      timeToLive: number;
      evaluation: string;
      accountId: string;
    };
  };
  _links: {
    self: LinkWithName;
  };
};

type EvaluationUploadResponse = {
  evaluationId: string;
  _links: {
    evaluation: LinkWithName;
  };
};

export async function uploadTesterState({
  accountId,
  configurationState,
  testerState,
  productConfigurationUrl,
  routingResults,
  environment,
  routingRequestLink,
}: {
  accountId: string;
  configurationState: SelectedConfigurationState;
  testerState: TestInputState;
  productConfigurationUrl: string;
  routingResults?: RoutingResult;
  environment: string;
  routingRequestLink: LinkWithName;
}) {
  const payload: Record<string, any> = {
    configurationState,
    testerState,
    productConfigurationUrl,
    routingResults,
    environment,
    routingRequestLink,
    uuid: uuid(), // To force the hash to be different
  };

  const zippedPayload = zipurl(JSON.stringify(payload));

  return await fetchWithAuth<EvaluationUploadResponse>({
    endpointUrl: `${baseUrl}v1/evaluations`,
    method: 'POST',
    body: {
      evaluation: zippedPayload,
      accountId,
    },
  });
}

export async function downloadTesterStateById(id: string) {
  const res = await fetchWithAuth<EvaluationResponse>({
    endpointUrl: `${baseUrl}v1/evaluations/${id}`,
  });

  const payload = unzipurl(res._embedded.evaluationState.evaluation);
  return JSON.parse(payload);
}
