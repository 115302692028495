import _ from 'lodash';

export function formatHttpErrorMessage(e: any): string[] {
  if (!e.response) {
    return [e.message];
  }

  const errors: string[] = [];

  if (e.response?.additionalDetails) {
    const details = _.map(
      Object.keys(e.response.additionalDetails),
      (key) => `${key}: ${e.response.additionalDetails[key]}`,
    );
    errors.push(...details);
  }
  if (e?.responseMessage) {
    errors.push(..._.split(e?.responseMessage, '\n'));
  }
  return errors;
}
